import * as Highcharts from 'highcharts';
import { OfficeCountsChartType } from './all.type';

const reminderData = [{
    title: WordList.ProperAllTextOfflineDevices,
    icon: require('@/assets/image/Offline_Devices.png'),
    enable: true,
    name: 'Offline',
    type: 'red',
    path: '/propertyIndex/device?Status=0'
}, {
    title: WordList.ProperAllTextInactivatedPeople,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Inactivated',
    type: 'yellow',
    path: '/propertyIndex/resident?Active=2'
}, {
    title: WordList.ProperAllTextExpiringPeople,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Expiring',
    type: 'orange',
    path: '/propertyIndex/resident?Active=3'
}, {
    title: WordList.ProperAllTextExpiredPeople,
    icon: require('@/assets/image/residents.png'),
    enable: true,
    name: 'Expired',
    type: 'purple',
    path: '/propertyIndex/resident?Active=4'
}];

const infoData = [{
    title: WordList.ProperAllTextDepartments,
    icon: require('@/assets/image/Departments.png'),
    enable: true,
    name: 'Department',
    path: '/propertyIndex/apartment'
}, {
    title: WordList.BasicUser,
    icon: require('@/assets/image/Personnels.png'),
    enable: true,
    name: 'Personnels',
    path: '/propertyIndex/resident?Role=30'
}, {
    title: WordList.AdvancedUser,
    icon: require('@/assets/image/Staffs.png'),
    enable: true,
    name: 'Staffs',
    path: '/propertyIndex/resident?Role=31'
}, {
    title: WordList.OrderDevice,
    icon: require('@/assets/image/Devices.png'),
    enable: true,
    name: 'DeviceAll',
    path: '/propertyIndex/device'
}];

// 门禁授权统计标签
const countsNav = [{
    label: WordList.BasicUser,
    key: 'Personnel',
    path: ''
}, {
    label: WordList.AdvancedUser,
    key: 'Staff',
    path: ''
}, {
    label: WordList.OfficeVisitor,
    key: 'TempKey',
    path: ''
}];

// 门禁授权统计图
const countsCharts = (countsData: number[], type: OfficeCountsChartType) => {
    const categories = {
        Personnel: [WordList.ProperAllTextPIN, WordList.DeliveryRfCard, WordList.ProperAllTextPhotos, WordList.NavInHtmlMenuApp],
        Staff: [WordList.ProperAllTextPIN, WordList.DeliveryRfCard, WordList.ProperAllTextPhotos, WordList.NavInHtmlMenuApp],
        TempKey: [WordList.ProperAllTextTempKey]
    };
    const countsNumData = countsData.map((item) => Number(item));
    let noData = false;
    if (countsNumData.every((num) => num.toString() === '0')) {
        noData = true;
    }
    Highcharts.chart('counts-container', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories[type]
        },
        yAxis: noData ? {
            min: 0,
            max: 1,
            tickInterval: 0.2,
            title: {
                text: ''
            }
        } : {
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{y}'
                }
            },
            // 设置壮壮图的柱宽
            column: {
                pointWidth: 75
            }
        },
        series: [{
            name: '',
            data: countsNumData,
            type: 'column'
        }],
        colors: ['#18BAFD']
    });
};

export default null;
export {
    reminderData,
    infoData,
    countsCharts,
    countsNav
};
